import { template as template_3d4464abb08244dc98b702ca4b83dde6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3d4464abb08244dc98b702ca4b83dde6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
