import { template as template_4b58c753a6294322a598d78e9248e391 } from "@ember/template-compiler";
const FKLabel = template_4b58c753a6294322a598d78e9248e391(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
