import { template as template_9e2ca49c09704c5dbbfadd079af5d121 } from "@ember/template-compiler";
const SidebarSectionMessage = template_9e2ca49c09704c5dbbfadd079af5d121(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
